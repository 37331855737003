<template>
  <div class="bg-light" style="min-height: 100vh">
    <el-header>
      <Header></Header>
    </el-header>
    <div class="search bg-light">
      <div class="container">
        <el-row style="padding: 10px 0;">
          <el-col :span="12">
            <el-input
              placeholder="请输入内容"
              v-model="input"
              clearable>
            </el-input>
          </el-col>
          <el-col :span="6" :offset="1">
            <el-button @click="showAllCourse" style="width: 130px" icon="el-icon-search" type="primary" round>查找
            </el-button>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="container">
      <div class="course">
        <el-row :gutter="20">
          <el-col :md="12" :xs="24" v-for="course in courses" :key="course.id">
            <div class="courseBox" @click="goToCourse(course.id)">
              <el-row>
                <el-col :span="10">
                  <div class="image">
                    <img v-if="course.courseInfo!==null" :src="course.courseInfo.imageUrl" alt="">
                    <el-image v-else>
                      <div slot="error" class="image-slot">
                        <i class="el-icon-picture-outline"></i>
                      </div>
                    </el-image>
                  </div>
                </el-col>
                <el-col :span="14" style="padding-left: 15px">
                  <h3>{{ course.name }}</h3>
                  <div class="courseSketch">
                    <p v-if="course.courseInfo!==null">{{ course.courseInfo.sketch }}</p>
                  </div>
                </el-col>
              </el-row>
            </div>
          </el-col>
        </el-row>
        <div class="block" style="text-align: center;padding-bottom: 100px">
          <span class="demonstration">调整每页显示条数</span>
          <el-pagination
            @size-change="handlePageSizeChange"
            @current-change="showAllCourse"
            :current-page.sync="currentPage"
            :page-sizes="pageSizes"
            :page-size="pageSize"
            layout="sizes, prev, pager, next"
            :total="total">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header'
import axios from 'axios'

export default {
  name: 'Courses',
  components: { Header },
  data () {
    return {
      input: '',
      courses: [],
      currentPage: 1,
      pageSize: 6,
      pageSizes: [6, 12, 20],
      total: 0
    }
  },
  created () {
    this.loadSearch()
  },
  methods: {
    loadSearch () {
      this.input = ''
      const searchInput = this.$route.params.searchInput
      if (searchInput !== null && searchInput !== '') {
        this.input = searchInput
        this.showAllCourse()
      } else {
        this.input = ''
        this.showAllCourse()
      }
    },
    goToCourse (id) {
      this.$router.push({
        name: 'Course',
        params: {
          courseId: id
        }
      })
    },
    handlePageSizeChange (val) {
      this.pageSize = val
      this.showAllCourse()
    },
    showAllCourse () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/course/showCoursesByLikeName',
        params: {
          likeName: this.input,
          currentPage: this.currentPage,
          pageSize: this.pageSize
        }
      }).then(res => {
        if (res.data.status) {
          this.courses = res.data.data.content
          this.total = res.data.data.totalCount
        } else {
          this.$message.error(res.data.message)
        }
      })
    }
  }
}
</script>

<style scoped>
.search {
  margin-top: 100px;
  margin-bottom: 100px;
  position: sticky;
  top: 0;
  z-index: 100;
}

.courseBox {
  padding: 0 !important;
  margin-bottom: 50px;
  height: 220px;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  border-radius: 10px;
  cursor: pointer;
}

.courseBox img {
  width: 100%;
  height: 220px;
  object-fit: cover;
  margin: 0;
  overflow: hidden;
  transition: .5s ease-in-out;
}

.image {
  overflow: hidden;
  height: 220px;
}

.courseBox img:hover {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-transform-origin: center center;
  -moz-transform-origin: center center;
  -o-transform-origin: center center;
  transform-origin: center center;
}

.courseBox h2 {
  margin-left: 20px;
}

.courseBox p {
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 0;
}

.courseSketch {
  width: 100%;
  height: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
